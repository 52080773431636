import React,{Suspense} from 'react'
import PropTypes from 'prop-types'
import Loader from '../../adapters/helpers/Loader';

function BodyText({children, tag: Tag, id, className, styles, whiteText}) {

    if (!children) {
        return null
    }

    className = className ? `ob-bodyText ${className}` : 'ob-bodyText';

    return (
        <Suspense fallback={<Loader />}>
        <Tag id={id} className={className} data-white-text={whiteText} style={styles} dangerouslySetInnerHTML={{__html: children}}></Tag>
        </Suspense>
    )
}

BodyText.propTypes = {
    /**
    * The html to be structured
    */
    children: PropTypes.node,

    /**
    * The tag to be used for the containing element
    */
    tag: PropTypes.string,

    /**
    * The ID attribute be added to the element
    */
    id: PropTypes.string,

    /**
    * The Class attribute be added to the element
    */
    className: PropTypes.string,

    /**
    * Set white text if needed
    */
    whiteText: PropTypes.bool,
    
    styles: PropTypes.object,
}

BodyText.defaultProps = {
    tag: 'p',
    className: '',
    styles: {},
    whiteText: false
}

export default BodyText

